import React from "react";
import styles from "./contact.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function About() {
  return (
    <div className={styles.section} id="contact">
      <div className={styles.imageLg}></div>
      <div className={styles.content}>
        <div>
          <h3>Have a question? Get in touch!</h3>
          <p style={{ marginTop: "60px" }}>
            My Travel Person
            <br />
            Green Point, Cape Town
          </p>
          <p>
            Telephone: +27 64 904 0254
            <br />
            Email: diana@mytravelperson.co.za
          </p>
        </div>
        <div style={{ marginTop: "60px" }}>
          <a href="https://www.facebook.com/mytravelperson" target='_blank'>
            <FontAwesomeIcon icon={faFacebook} size="3x" />
          </a>
          <a href="https://www.instagram.com/mytravelperson/" target='_blank'>
            <FontAwesomeIcon icon={faInstagramSquare} size="3x" />
          </a>
          <a href="https://www.linkedin.com/in/diana-steele-my-travel-person-86a13755" target='_blank'>
            <FontAwesomeIcon icon={faLinkedin} size="3x" />
          </a>
        </div>
      </div>
      <div className={styles.imageSm}></div>
    </div>
  );
}