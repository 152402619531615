import React from "react";
import styles from "./testimonials.module.scss";
import Slider from "../components/Carousel";

export default function Testimonials() {
  return (
    <div className={styles.section} id="testimonials">
      <Slider />
    </div>
  );
}