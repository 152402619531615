import React from "react";
import styles from "./about.module.scss";
import profile from "../assets/profile-square-bg.png";

export default function About() {
  return (
    <div className={styles.section} id="about">
      <div className={styles.content}>
        <img alt="profile image" src={profile} className={styles.profile} />
        <p>
          I have over 15 years experience in the travel industry specializing in corporate travel for small to medium businesses and leisure travel.
          My business “My Travel Person” operates under eTravel, the first and most established Travel Consortium for Independent Travel Consultants in South Africa where I have access to the best contracted and exclusive rates.
          The eTravel group has grown to become one of the largest independently owned travel companies in South Africa with an annual turnover and market share that ensures a buying power large enough to have access to the best rates, contracted fares and preferred supplier relationships.
        </p>
        <p>
          Myself or one of the team are always available to call or whatsapp even after hours for any assistance that is required.
          I will assist you with all travel requirements, advice on current changing regulations and documentation required.
          With a more personal service I get to know my clients and their needs and always prioritise my regular clients with fast turnaround time. 
        </p>
        <p>
           Where service and assistance makes the difference! Before, during AND after your journey 😊
        </p>
        <p>
          Looking forward to being your Travel Person! ✈💙
        </p>
        <h3>Diana</h3>
      </div>
      <div className={styles.image}></div>
    </div>
  );
}