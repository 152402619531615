import React, { useState } from "react";
import LazyHero from 'react-lazy-hero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

import Maldives from '../assets/maldives.jpg';
import logo from "../assets/logo-full.png";

export default function Header() {
  const [headerLogo, setHeaderLogo] = useState(true);

  function toggleLogo() {
    if (window.scrollY >= 100) {
      setHeaderLogo(false);
    } else {
      setHeaderLogo(true);
    }
  }

  window.addEventListener("scroll", toggleLogo);

  return (
    <div id="home">
      <LazyHero imageSrc={Maldives} minHeight="100vh" opacity="0">
        <div style={{ backgroundColor: "rgba(255,255,255,0.4)", borderRadius: "50%", width: "300px", height: "300px" }}
          className={headerLogo ? "show" : "hide"}
        >
          <img src={logo} width="200px" />
        </div>
      </LazyHero>
      <a className="arrow" href="#about">
        <FontAwesomeIcon icon={faAngleDoubleDown} size="3x" />
      </a>
    </div>
  );
}