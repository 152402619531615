import React, { useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styles from "./navbar.module.scss";
import logo from "../assets/logo.png";
import logoText from "../assets/logo-text.png";

export default function NavBar() {
  const [navbar, setNavbar] = useState(false);

  function changeBackground() {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }

  window.addEventListener("scroll", changeBackground);

  return (
    <Navbar collapseOnSelect expand="lg" className={navbar ? styles.colored : styles.transparent} fixed="top">
      <Navbar.Brand href="#home">
        <img alt="logo" src={logo} width="50px" />
        <img alt="logo text" src={logoText} height="24px" />
        {/* my <strong>travel</strong> person */}
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
        </Nav>
        <Nav style={{ backgroundColor: "rgb(81, 115, 179)" }}>
          <Nav.Link href="#about" style={{ padding: "1rem" }} >About</Nav.Link>
          <Nav.Link href="#testimonials" style={{ padding: "1rem" }} >Testimonials</Nav.Link>
          <Nav.Link href="#contact" style={{ padding: "1rem" }} >Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}