import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import styles from "./navbar.module.scss";

export default function Slider() {
  return (
    <Carousel style={{ backgroundColor: "rgba(255,255,255,0.4)", minWidth: "70vw", borderRadius: "50px" }}>
      <Carousel.Item>
        <h5 style={{ minHeight: "400px", textAlign: "center", padding: "6rem", display: "flex", alignItems: "center", color: "white", fontSize: "16px" }}>
          "Diana is most definitely a professional at what she does. Her guidance is on point. Under the circumstances, traveling (and organizing it) these days can be quite daunting but Diana does it all for you. Where and when to do the COVID test, info on exit and point of entry, forms to be filled and assistance doing it, online check in and more.
          I highly recommend her as your travel person."
        </h5>
        <Carousel.Caption>
          <h3 style={{ fontSize: "18px" }}>Claudia Bastos Scoufes</h3>
          <p style={{ fontSize: "14px" }}>December 6, 2020</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <h5 style={{ minHeight: "400px", textAlign: "center", padding: "6rem", display: "flex", alignItems: "center", color: "white", fontSize: "16px" }}>
          "Amazing Service ...Fast Efficient and go the Extra mile to help you gave a wonderful trip..
          I did my first flight in covid with help of the team and it was so helpful and everything explained and had me 100 percent prepared.highly recommended."
        </h5>
        <Carousel.Caption>
          <h3 style={{ fontSize: "18px" }}>Neeitesh Desai</h3>
          <p style={{ fontSize: "14px" }}>November 2, 2020</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <h5 style={{ minHeight: "400px", textAlign: "center", padding: "6rem", display: "flex", alignItems: "center", color: "white", fontSize: "16px" }}>
          "A most efficient agency that made my rather difficult trip very easy. I highly recommend Diana to everyone traveling especially at this time."
        </h5>
        <Carousel.Caption>
          <h3 style={{ fontSize: "18px" }}>Sally Earl</h3>
          <p style={{ fontSize: "14px" }}>November 29, 2020</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <h5 style={{ minHeight: "400px", textAlign: "center", padding: "6rem", display: "flex", alignItems: "center", color: "white", fontSize: "16px" }}>
          "Efficient and very knowledgeable. Very reassuring to have good advice especially during the covid crises."
        </h5>
        <Carousel.Caption>
          <h3 style={{ fontSize: "18px" }}>Debbie Kent</h3>
          <p style={{ fontSize: "14px" }}>December 14, 2020</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <h5 style={{ minHeight: "400px", textAlign: "center", padding: "6rem", display: "flex", alignItems: "center", color: "white", fontSize: "16px" }}>
          "Best service and Customer relations."
        </h5>
        <Carousel.Caption>
          <h3 style={{ fontSize: "18px" }}>Costar Takawira</h3>
          <p style={{ fontSize: "14px" }}>December 9, 2020</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}