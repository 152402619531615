import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './app.module.scss';
import Header from '../src/sections/Header';
import About from './sections/About';
import Testimonials from './sections/Testimonials';
import Contact from './sections/Contact';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className={styles.container}>
      <NavBar />
      <Header />
      <About />
      <Testimonials />
      <Contact />
      <div className={styles.footer}>
        <p>© {(new Date().getFullYear())} My Travel Person.</p>
        <p>Website developed by Leigh Steele.</p>
      </div>
    </div>
  );
}

export default App;
